import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { FolderInfo, KeyResult, MilestoneDates, OKRData, PlanDefinition, PlanMilestoneData, PlanMenuInfo, ProgressInfo as ProgressInfo, TimelineLayerItem, TimelineMilestoneData, TimeRange, PredefinedTimeRange } from "./interfaces";

import { v4 as uuidv4 } from 'uuid';

import moment from 'moment';
import dayjs, { Dayjs } from "dayjs";

import logger from "@/lib/logger";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const formatDateButtonText = (date: Dayjs | null) => {
  if (!date) return '';
  const now = dayjs();
  if (date.year() === now.year()) {
    if (date.month() === now.month() && date.date() === now.date()) {
      return 'Today';
    }
    return date.format('MMM D');
  }
  return date.format('MMM D, YYYY');
};

// Function to format date in a human-readable form
export function formatDate(date): string {
  if (!date) {
    return '';
  }

  return moment(date).format('LL'); // 'LL' for format like 'January 1, 2022'
}

export function isFullMonth(s, e) {
  if (!s || !e) return false;
  const start = moment(s);
  const end = moment(e);
  return start.date() === 1 && end.isSame(start.clone().endOf('month'), 'day');
}

export function isFullOrMultipleMonths(s, e) {
  if (!s || !e) return false;
  const start = moment(s);
  const end = moment(e);

  // Check if the start date is the first day of its month
  const isStartOfMonth = start.isSame(start.clone().startOf('month'), 'day');

  // Check if the end date is the last day of its month
  const isEndOfMonth = end.isSame(end.clone().endOf('month'), 'day');

  // If not starting or ending on month boundaries
  if (!isStartOfMonth || !isEndOfMonth) {
    return false;
  }

  // Calculate the number of months between start and end
  const monthsBetween = (end.year() - start.year()) * 12 + end.month() - start.month() + 1;

  // Return true if it spans one or more full months
  return monthsBetween >= 1;
}

export function isFullOrMultipleQuarters(s, e) {
  if (!s || !e) return false;

  const start = moment(s);
  const end = moment(e);

  // Ensure both dates are the start of a day
  if (!start.isSame(start.clone().startOf('day'), 'day') ||
    !end.isSame(end.clone().startOf('day'), 'day')) {
    return false;
  }

  // Check if the start date is the first day of its quarter
  const isStartOfQuarter = start.isSame(start.clone().startOf('quarter'), 'day');

  // Check if the end date is the last day of its quarter
  const isEndOfQuarter = end.isSame(end.clone().endOf('quarter'), 'day');

  // If not starting or ending on quarter boundaries
  if (!isStartOfQuarter || !isEndOfQuarter) {
    return false;
  }

  // Calculate the number of quarters between start and end
  const quartersBetween = (end.year() - start.year()) * 4 + end.quarter() - start.quarter() + 1;

  // Return true if it spans one or more quarters
  return quartersBetween >= 1;
}

export function isFullYear(s, e) {
  if (!s || !e) return false;
  const start = moment(s);
  const end = moment(e);
  return start.month() === 0 && start.date() === 1 && end.month() === 11 && end.date() === 31 && start.year() === end.year();
}

export function formatMonthAndYear(date) {
  return date ? moment(date).format('MMMM YYYY') : '';
}

export function formatQuarters(s, e) {
  if (!s || !e) return '';

  const startMoment = moment(s);
  const endMoment = moment(e);

  // Calculate the quarters by using moment's quarter() method
  const startQuarter = startMoment.quarter();
  const endQuarter = endMoment.quarter();
  const startYear = startMoment.year();
  const endYear = endMoment.year();

  if (startQuarter === endQuarter && startYear === endYear) {
    return `Q${startQuarter} ${startYear}`;
  } else if (startYear === endYear) {
    return `Q${startQuarter}-Q${endQuarter} ${startYear}`;
  } else {
    return `Q${startQuarter} ${startYear}-Q${endQuarter} ${endYear}`;
  }
}

export function formatMonths(start, end) {
  if (!start || !end) return '';

  const startMoment = moment(start);
  const endMoment = moment(end);

  // Format the start and end dates
  const startFormat = startMoment.format('MMMM YYYY');
  const endFormat = endMoment.format('MMMM YYYY');

  // If the start and end dates are in the same month and year
  if (startFormat === endFormat) {
    return startFormat;
  } else {
    // For different months/years
    return `${startFormat} to ${endFormat}`;
  }
}

export function describeTimeRange(start, end) {
  // Convert to moment objects if they are not already
  const startDate = moment.isMoment(start) ? start : moment(start);
  const endDate = moment.isMoment(end) ? end : moment(end);

  // Check for invalid dates
  if (!startDate.isValid() || !endDate.isValid()) {
    return "Invalid date(s). Please enter valid date strings.";
  }

  // Check if start date is after end date
  if (startDate.isAfter(endDate)) {
    return "Start date must be before end date.";
  }

  // Check for same day
  if (startDate.isSame(endDate, 'day')) {
    return startDate.format('MMMM Do, YYYY'); // E.g., "January 1st, 2022"
  }

  // Check for full year
  if (isFullYear(startDate, endDate)) {
    return startDate.format('YYYY'); // E.g., "2022"
  }

  // Check for full quarter or spanning multiple quarters
  if (isFullOrMultipleQuarters(startDate, endDate)) {
    return formatQuarters(startDate, endDate); // E.g., "Q1-Q2 2022"
  }

  // Check for full month
  if (isFullOrMultipleMonths(startDate, endDate)) {
    return formatMonths(startDate, endDate); // E.g., "January 2022"
  }

  // Default to generic date range
  return `${startDate.format('MMMM Do, YYYY')} to ${endDate.format('MMMM Do, YYYY')}`; // E.g., "January 1, 2022 to January 15, 2022"
}

export function toLocalDateISOString(d): string {
  const date = new Date(d);

  return date.getFullYear() + '-' +
    String(date.getMonth() + 1).padStart(2, '0') + '-' + // months are 0-indexed
    String(date.getDate()).padStart(2, '0');
}

export function createDateFromLocalDateString(dateString: string): Date {
  return moment(dateString, 'YYYY-MM-DD').toDate();
}

// Calculate time progress as a percentage
export function getTimeProgress(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const today = new Date();

  if (today <= start) return 0;
  if (today >= end) return 100;

  const totalDuration = new Date(endDate).getTime() - new Date(startDate).getTime();
  const elapsedDuration = new Date().getTime() - new Date(startDate).getTime();

  return (elapsedDuration / totalDuration) * 100;
}

// Helper function to calculate the difference in months
function monthDiff(startDate, endDate) {
  var months;
  months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
  months -= startDate.getMonth();
  months += endDate.getMonth();
  return months <= 0 ? 0 : months;
}
// Helper function for formatting elapsed or remaining time
function formatElapsedTime(years, months, days) {
  let parts = [];
  if (years > 0) parts.push(`${years} year${years > 1 ? 's' : ''}`);
  if (months > 0) parts.push(`${months} month${months > 1 ? 's' : ''}`);
  if ((years === 0 || months === 0) && days > 0) parts.push(`${days} day${days > 1 ? 's' : ''}`);

  return parts.join(' and ');
}

export function getTimeElapsed(start, end) {
  const startDate = convertToDate(start);
  const endDate = convertToDate(end);
  let today = new Date();

  if (today < startDate) {
    return "0 days";
  } else if (today > endDate) {
    today = endDate;
  }

  let elapsedYears = today.getFullYear() - startDate.getFullYear();
  let elapsedMonths = monthDiff(startDate, today) - elapsedYears * 12;
  let elapsedDays = today.getDate() - startDate.getDate();

  if (elapsedDays < 0) {
    elapsedMonths--;
    let previousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    elapsedDays += new Date(previousMonth.getFullYear(), previousMonth.getMonth() + 1, 0).getDate();
  }

  if (elapsedMonths < 0) {
    elapsedYears--;
    elapsedMonths += 12;
  }

  return formatElapsedTime(elapsedYears, elapsedMonths, elapsedDays);
}

// Enhanced getTimeRemaining method
export function getTimeRemaining(start, end) {
  const endDate = convertToDate(end);
  let today = new Date();

  if (today > endDate) {
    return "0 days";
  }

  let remainingYears = endDate.getFullYear() - today.getFullYear();
  let remainingMonths = monthDiff(today, endDate) - remainingYears * 12;
  let remainingDays = endDate.getDate() - today.getDate();

  if (remainingDays < 0) {
    remainingMonths--;
    let nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    remainingDays += new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 0).getDate();
  }

  if (remainingMonths < 0) {
    remainingYears--;
    remainingMonths += 12;
  }

  return formatElapsedTime(remainingYears, remainingMonths, remainingDays);
}

export const getConfettiColors = (effectiveTheme) => {
  return effectiveTheme === 'dark' ?
    ['#90caf9', '#f48fb1', '#ffeb3b', '#69f0ae', '#1de9b6'] : // Colors for dark theme
    ['#1976d2', '#d81b60', '#ff5722', '#9c27b0', '#009688'];  // Colors for light theme
}

export function getProgressStatus(progressInfo: ProgressInfo) {
  const today = new Date();
  const startDate = new Date(progressInfo.startDate);
  const endDate = new Date(progressInfo.endDate);

  if (progressInfo.progress === 100) return 'Completed';
  if (progressInfo.progress === 0 && today < startDate) return 'Not Started';
  if (today > endDate && progressInfo.progress < 100) return 'Missed';
  if (today >= startDate && today <= endDate) return 'In Progress';
  if (today < startDate) return 'Upcoming';

  return 'Unknown';
}

export const getProgressStatusColor = (progressInfo: ProgressInfo, effectiveTheme) => {
  const status = getProgressStatus(progressInfo);

  switch (status) {
    case 'Not Started':
      return effectiveTheme === 'dark' ? '#e0e0e0' : '#9e9e9e'; // Light grey in dark mode, darker grey in light mode
    case 'In Progress':
      return effectiveTheme === 'dark' ? '#f4e8a6' : '#ecd96a'; // Yellow
    case 'Missed':
      return effectiveTheme === 'dark' ? '#ffcdd2' : '#f39999';  // Red
    case 'Completed':
      return effectiveTheme === 'dark' ? '#aed5af' : '#8fc590';  // Green
    default:
      return effectiveTheme === 'dark' ? '#fff' : '#1a1a1a'; // White in dark mode, black in light mode
  }
};

export function addDuration(startDate, milestoneDuration, numberOfMilestones) {
  let endDate = moment(startDate);

  for (let i = 0; i < numberOfMilestones; i++) {
    const durationValue = parseInt(milestoneDuration.slice(0, -1));
    const durationUnit = milestoneDuration.slice(-1).toUpperCase();

    switch (durationUnit) {
      case 'D': // Days
        endDate.add(durationValue, 'days');
        break;
      case 'W': // Weeks
        endDate.add(durationValue, 'weeks');
        break;
      case 'M': // Months
        endDate.add(durationValue, 'months');
        break;
      case 'Y': // Years
        endDate.add(durationValue, 'years');
        break;
      default:
        throw new Error(`Unsupported duration unit: ${durationUnit}`);
    }
  }

  return endDate.toISOString();
}

export function generateDefaultMilestones(startDate: Date | null = null, milestoneDuration: string = '1M', numberOfMilestones: number = 3): PlanMilestoneData[] {
  const milestones: PlanMilestoneData[] = [];

  // If startDate is not defined, use the start of the next month
  if (!startDate) {
    const today = new Date();
    startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  }

  // Helper functions to add days, months, and years to a date
  const addDays = (date: Date, days: number): Date => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  };

  const addMonths = (date: Date, months: number): Date => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
  };

  const addYears = (date: Date, years: number): Date => {
    const newDate = new Date(date);
    newDate.setFullYear(newDate.getFullYear() + years);
    return newDate;
  };

  for (let i = 0; i < numberOfMilestones; i++) {
    // Parse the duration and add the correct number of days, months, or years
    const durationValue = parseInt(milestoneDuration.slice(0, -1));
    const durationUnit = milestoneDuration.slice(-1);

    let start, end;

    switch (durationUnit) {
      case 'D': // For day-based durations
        start = addDays(startDate, i * durationValue);
        end = addDays(start, durationValue);
        break;
      case 'W': // For week-based durations
        start = addDays(startDate, i * durationValue * 7);
        end = addDays(start, durationValue * 7);
        break;
      case 'M': // For month-based durations
        start = addMonths(startDate, i * durationValue);
        end = addMonths(start, durationValue);

        if (end.getDate() == 1) {
          end = addDays(end, -1);
        }

        break;
      case 'Y': // For year-based durations
        start = addYears(startDate, i * durationValue);
        end = addYears(start, durationValue);

        if (end.getDate() == 1) {
          end = addDays(end, -1);
        }

        break;
      default:
        // Handle other duration types or throw an error
        throw new Error(`Unsupported duration unit: ${durationUnit}`);
    }

    milestones.push({
      id: uuidv4(),
      startDate: start.toISOString(),
      endDate: end.toISOString(),
      description: describeTimeRange(start, end)
    } as PlanMilestoneData);
  }

  return milestones;
}

export function getDurationText(duration) {
  if (!duration) return '';

  const patterns = {
    Y: { regex: /(\d+)Y/, singular: 'year', plural: 'years' },
    M: { regex: /(\d+)M/, singular: 'month', plural: 'months' },
    W: { regex: /(\d+)W/, singular: 'week', plural: 'weeks' },
    D: { regex: /(\d+)D/, singular: 'day', plural: 'days' },
  };

  let durationText = duration;

  Object.values(patterns).forEach(({ regex, singular, plural }) => {
    durationText = durationText.replace(regex, (match, number) => {
      return `${number} ${number > 1 ? plural : singular}`;
    });
  });

  // If no replacements have been made, duration was not in an expected format
  if (durationText === duration) return 'Invalid duration format';

  return durationText;
}

export function calculatePlanDuration(milestones: PlanMilestoneData[]): number {
  logger.debug('Calculating plan duration', milestones);

  if (milestones.length < 1) {
    logger.error('No milestones found in calculatePlanDuration');
    return 0;
  }

  // Sort milestones by startDate and endDate
  const sortedMilestones = milestones.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

  const startDate = new Date(sortedMilestones[0].startDate);
  const endDate = new Date(sortedMilestones[sortedMilestones.length - 1].endDate);

  return Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)); // Duration in days
}

function convertToDate(input) {
  // logger.debug("convertToDate received:", input);

  let momentDate;

  if (input instanceof Date) {
    momentDate = moment(input);
  } else if (typeof input === 'number') {
    momentDate = moment.unix(input);
  } else if (input && typeof input === 'object') {
    if ('seconds' in input) {
      // Firestore Timestamp format
      momentDate = moment.unix(input.seconds);
      if ('nanoseconds' in input) {
        momentDate.add(input.nanoseconds / 1000000, 'milliseconds');
      }
    } else if ('_seconds' in input && '_nanoseconds' in input) {
      // The specific format you encountered
      momentDate = moment.unix(input._seconds);
      momentDate.add(input._nanoseconds / 1000000, 'milliseconds');
    }
  } else if (typeof input === 'string') {
    momentDate = moment(input);
  }

  if (!momentDate || !momentDate.isValid()) {
    logger.error("Invalid date:", input);
    logger.warn("Returning today's date as fallback");
    return new Date();
  }

  return momentDate.toDate();
}

export function adjustMilestones(milestones, newStartDate) {
  if (!milestones || milestones.length === 0) {
    return [];
  }

  // Convert the first milestone start date and new start date to Moment objects
  const firstMilestoneStartDate = moment(convertToDate(milestones[0].startDate));
  const newStartMoment = moment(convertToDate(newStartDate));

  // Calculate year, month, and day shifts
  const yearShift = newStartMoment.year() - firstMilestoneStartDate.year();
  const monthShift = newStartMoment.month() - firstMilestoneStartDate.month();
  const dayShift = newStartMoment.date() - firstMilestoneStartDate.date();

  return milestones.map(milestone => {
    // Convert milestone start and end dates to Moment objects
    let milestoneStartDate = moment(convertToDate(milestone.startDate));
    let milestoneEndDate = moment(convertToDate(milestone.endDate));

    logger.debug('Milestone dates', { milestoneStartDate, milestoneEndDate });

    // Adjust start and end dates by the year, month, and day shifts
    let adjustedStart = milestoneStartDate.add(yearShift, 'years').add(monthShift, 'months').add(dayShift, 'days');
    let adjustedEnd = milestoneEndDate.add(yearShift, 'years').add(monthShift, 'months').add(dayShift, 'days');

    // Check if adjusted dates are valid
    if (!adjustedStart.isValid() || !adjustedEnd.isValid()) {
      logger.error('Invalid date found in adjustMilestones', { adjustedStart, adjustedEnd });
      return milestone; // Return the original milestone if dates are invalid
    }

    logger.debug('Adjusted dates', { adjustedStart, adjustedEnd });

    // Check if original start date was the first day of the month
    if (milestoneStartDate.date() === 1) {
      adjustedStart.startOf('month');
    }

    // Check if original end date was the last day of the month
    if (milestoneEndDate.date() === milestoneEndDate.clone().endOf('month').date()) {
      adjustedEnd.endOf('month');
    }

    return {
      ...milestone,
      startDate: adjustedStart.toISOString(),
      endDate: adjustedEnd.toISOString(),
      description: describeTimeRange(adjustedStart.toDate(), adjustedEnd.toDate())
    };
  });
}

// Note: This is not an API endpoint that is currently being used.
export async function createAIPlan(prompt: string, startDate: Date, milestoneDuration: string, numberOfMilestones: number): Promise<PlanDefinition> {
  // Call to the AI endpoint (mocked for this example)
  const response = await fetch('/api/generate-plan', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ prompt, startDate, milestoneDuration, numberOfMilestones }),
  });

  if (!response.ok) {
    throw new Error(`AI endpoint responded with status: ${response.status}`);
  }

  const data = await response.json();

  // Assuming the AI returns data that can be directly mapped to PlanDefinition
  const planDefinition: PlanDefinition = data;

  return planDefinition;
}

export function createBlankPlan(title: string, startDate: Date | null, milestoneDuration: string, numberOfMilestones: number): PlanDefinition {
  let milestones = generateDefaultMilestones(startDate, milestoneDuration, numberOfMilestones);

  return {
    title: title || "Your Plan Title",
    description: "Your Plan Description",
    events: milestones,
    okrs: [],
    retros: [],
    icons: {},
  } as PlanDefinition;
}

export function getTotalDurationText(numberOfMilestones, milestoneDuration) {
  const durationNumber = parseInt(milestoneDuration);
  const durationUnit = milestoneDuration.replace(/[0-9]/g, '');

  // Define a mapping of duration units to their corresponding days
  const daysInUnit = {
    D: 1,
    W: 7,
    M: 30.44, // Average days in a month
    Y: 365.25, // Average days in a year (accounting for leap years)
  };

  if (daysInUnit[durationUnit]) {
    // Calculate the total days
    const totalDays = durationNumber * numberOfMilestones * daysInUnit[durationUnit];

    // Convert totalDays into years, months, and days
    const years = Math.floor(totalDays / 365.25);
    const remainingDays = totalDays % 365.25;
    const months = Math.floor(remainingDays / 30.44);
    const days = Math.floor(remainingDays % 30.44);

    // Construct a readable string
    let readableDuration = '';
    if (years > 0) {
      readableDuration += `${years} ${years === 1 ? 'year' : 'years'}`;
    }
    if (months > 0) {
      if (readableDuration.length > 0) readableDuration += ' and ';
      readableDuration += `${months} ${months === 1 ? 'month' : 'months'}`;
    }
    if (days > 0 && years === 0) { // Include days only if there are no years
      if (readableDuration.length > 0) readableDuration += ' and ';
      readableDuration += `${days} ${days === 1 ? 'day' : 'days'}`;
    }

    return readableDuration || '0 days';
  } else {
    return 'Invalid duration unit';
  }
}

export const calculateObjectiveProgress = (keyResults: KeyResult[]) => {
  if (!keyResults || !Array.isArray(keyResults) || keyResults.length === 0) return 0;

  // Filter out key results that are scratched
  const activeKeyResults = keyResults.filter(kr => !kr.scratched);

  // Calculate total progress only from non-scratched key results
  const totalKRProgress = activeKeyResults.reduce((acc, kr) => acc + kr.progress, 0);

  // Compute average progress if there are active key results, otherwise return 0
  const progress = activeKeyResults.length > 0 ? totalKRProgress / activeKeyResults.length : 0;

  return progress;
}

export const calculateTimelineProgressInfo = (timelineMilestones: TimelineMilestoneData[]): ProgressInfo => {
  const milestones: PlanMilestoneData[] = timelineMilestones.map(m => {
    // Override startDate and endDate with displayStartDate and displayEndDate
    const { displayStartDate, displayEndDate, ...rest } = m;
    return {
      ...rest,
      startDate: displayStartDate,
      endDate: displayEndDate,
    };
  });
  
  const okrs: OKRData[] = timelineMilestones.map((m) => m.okr ||
  {
    id: m.id,
    objective: ``,
    keyResults: []
  }
  );

  return calculateProgressInfo(milestones, okrs);
};

export function calculateProgressInfo(milestones: PlanMilestoneData[], okrs: OKRData[]) {
  let totalProgress = 0;
  let totalObjectives = 0;

  milestones.forEach((milestone) => {
    const objective = okrs.find(okr => okr.id === milestone.id) || { id: milestone.id, objective: "", keyResults: [] };

    if (objective.keyResults && objective.keyResults.length > 0) {
      totalProgress += calculateObjectiveProgress(objective.keyResults);
    }

    totalObjectives++;
  });

  const averageProgress = totalObjectives > 0 ? totalProgress / totalObjectives : 0;

  // Calculate date range
  const validStartDates = milestones.map(m => new Date(m.startDate)).filter(d => !isNaN(d.getTime()));
  const validEndDates = milestones.map(m => new Date(m.endDate)).filter(d => !isNaN(d.getTime()));

  const earliestStartDate = validStartDates.length > 0 ? new Date(Math.min(...validStartDates.map(d => d.setHours(0, 0, 0, 0)))) : new Date().setHours(0, 0, 0, 0);
  const latestEndDate = validEndDates.length > 0 ? new Date(Math.max(...validEndDates.map(d => d.setHours(0, 0, 0, 0)))) : new Date().setHours(0, 0, 0, 0);

  return {
    progress: averageProgress,
    startDate: new Date(earliestStartDate).toISOString(),
    endDate: new Date(latestEndDate).toISOString()
  };
}

export function calculateNewMilestoneDates(
  currentMilestone: PlanMilestoneData,
  previousMilestone: PlanMilestoneData | null,
  nextMilestone: PlanMilestoneData | null
): MilestoneDates {
  let newStartDate = moment(convertToDate(currentMilestone.startDate));
  let newEndDate = moment(convertToDate(currentMilestone.endDate));
  var changed = false;

  // Calculate potential new end date based on the original duration
  const originalDurationDays = newEndDate.diff(newStartDate, 'days');

  // Adjust the start date based on the previous milestone
  if (previousMilestone) {
    const dayAfterPreviousEnds = moment(previousMilestone.endDate).add(1, 'days');
    if (dayAfterPreviousEnds.isAfter(newStartDate)) {
      newStartDate = dayAfterPreviousEnds;
      changed = true;
    }
  }

  let potentialNewEndDate = newStartDate.clone().add(originalDurationDays, 'days');

  // Adjust the end date based on the next milestone
  if (nextMilestone) {
    const dayBeforeNextStarts = moment(nextMilestone.startDate).subtract(1, 'days');
    newEndDate = dayBeforeNextStarts;
    changed = true;
  } else {
    newEndDate = potentialNewEndDate;
    changed = true;
  }

  // Ensure the end date is after the start date, at least by a default duration
  if (newEndDate.isSameOrBefore(newStartDate)) {
    newEndDate = newStartDate.clone().add(Math.max(originalDurationDays, 7), 'days'); // default duration of 1 week
    changed = true;
  }

  return {
    startDate: newStartDate.toDate(),
    endDate: newEndDate.toDate(),
    changed
  };
}

export function findPlanItemAndParentById(items: PlanMenuInfo[], id: string, parent: FolderInfo | null = null, depth: number = 0): { item: PlanMenuInfo, parent: FolderInfo | null, index: number, depth: number } | null {
  if (!items || items.length === 0) return null;

  for (let i = 0; i < items.length; i++) {
    const item = items[i];

    if (item.id === id) {
      return { item, parent, index: i, depth };
    }

    if (item.type === 'folder' && item.items.length) {
      const found = findPlanItemAndParentById(item.items, id, item, depth + 1);
      if (found) {
        return found;
      }
    }
  }

  return null;
}

/**
 * Adds a new item (plan or folder) to the specified folder within the plans or to the root level if no folder is specified.
 * @param {PlanMenuInfo[]} plans - The current array of plans and folders.
 * @param {PlanMenuInfo} newItem - The new item to be added (plan or folder).
 * @param {string|null} targetFolderId - The ID of the folder to add the new item to, or null to add at the root level.
 */
export function addItemToPlans(plans, newItem, targetFolderId) {
  if (!targetFolderId) {
    // No specific folder, add to root level
    return [...plans, newItem];
  } else {
    // Find target folder and add the new item to it
    return plans.map(item => {
      if (item.id === targetFolderId && item.type === 'folder') {
        // Found the folder, add the new item to it
        return { ...item, isExpanded: true, items: [...item.items, newItem] };
      } else if (item.type === 'folder') {
        // Recursively update nested folders
        return { ...item, items: addItemToPlans(item.items, newItem, targetFolderId) };
      }
      return item;
    });
  }
}

export function findFirstItemByType(items: PlanMenuInfo[], type: string, parent: FolderInfo | null = null, depth = 0): { item: PlanMenuInfo, parent: FolderInfo | null, index: number, depth: number } | null {
  if (!items || items.length === 0) return null;

  for (let i = 0; i < items.length; i++) {
    const item = items[i];

    if ((!item.type && type === 'plan') || item.type === type) {
      return { item, parent, index: i, depth };
    }

    // If the item is a folder, recursively search within its items
    if (item.type === 'folder' && item.items.length) {
      const found = findFirstItemByType(item.items, type, item, depth + 1);

      if (found) {
        return found;
      }
    }
  }

  return null;
}

export function getMaxDepth(item, currentDepth = 0) {
  if (item.type !== 'folder' || !item.items || item.items.length === 0) {
    return currentDepth;
  }

  let depths = item.items.map(child => getMaxDepth(child, currentDepth + 1));

  return Math.max(...depths);
}

export const getFilteredKeyResult = (kr, filterOptions) => {
  const notStarted = kr.progress === 0 && !kr.scratched && filterOptions.notStarted;
  const inProgress = kr.progress > 0 && kr.progress < 100 && !kr.scratched && filterOptions.inProgress;
  const completed = kr.progress === 100 && !kr.scratched && filterOptions.completed;
  const scratched = kr.scratched && filterOptions.scratched;

  return notStarted || inProgress || completed || scratched;
}

export function flattenPlans(plans) {
  const flatList = [];

  function traverse(items) {
    if (!Array.isArray(items)) {
      return;
    }
    for (const item of items) {
      if (item.type !== 'folder') {
        flatList.push(item);
      } else if (item.items && item.items.length > 0) {
        traverse(item.items);
      }
    }
  }

  traverse(plans);
  return flatList;
}

export const adjustMilestoneDates = (milestones: TimelineMilestoneData[], start: Date, end: Date): TimelineMilestoneData[] => {
  return milestones.filter(milestone => {
    const milestoneStart = new Date(milestone.startDate);
    const milestoneEnd = new Date(milestone.endDate);
    return (milestoneStart <= end && milestoneEnd >= start);
  }).map(milestone => {
    const milestoneStart = new Date(milestone.startDate);
    const milestoneEnd = new Date(milestone.endDate);
    const displayStart = milestoneStart < start ? start : milestoneStart;
    const displayEnd = milestoneEnd > end ? end : milestoneEnd;

    return {
      ...milestone,
      displayStartDate: displayStart.toISOString(),
      displayEndDate: displayEnd.toISOString(),
    };
  }).filter(milestone => {
    const milestoneStart = new Date(milestone.displayStartDate);
    const milestoneEnd = new Date(milestone.displayEndDate);

    return (milestoneStart >= start && milestoneStart <= end) || (milestoneEnd >= start && milestoneEnd <= end);
  });
};

export const calculateTimeRange = (range: TimeRange) => {
  const now = new Date();

  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  const startOfWeek = new Date(now);
  startOfWeek.setDate(now.getDate() - now.getDay());
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  const startOfQuarter = new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3, 1);
  const endOfQuarter = new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3 + 3, 0);

  const startOfYear = new Date(now.getFullYear(), 0, 1);
  const endOfYear = new Date(now.getFullYear(), 11, 31);

  const sixMonthsAhead = new Date(startOfMonth);
  sixMonthsAhead.setMonth(sixMonthsAhead.getMonth() + 6);
  sixMonthsAhead.setDate(0);

  if (typeof range === 'string') {
    switch (range) {
      case PredefinedTimeRange.ThisMonth:
        return { start: startOfMonth, end: endOfMonth };
      case PredefinedTimeRange.LastMonth:
        return { start: new Date(now.getFullYear(), now.getMonth() - 1, 1), end: new Date(now.getFullYear(), now.getMonth(), 0) };
      case PredefinedTimeRange.ThisQuarter:
        return { start: startOfQuarter, end: endOfQuarter };
      case PredefinedTimeRange.LastQuarter:
        return { start: new Date(now.getFullYear(), Math.floor((now.getMonth() - 3) / 3) * 3, 1), end: new Date(now.getFullYear(), Math.floor((now.getMonth() - 3) / 3) * 3 + 3, 0) };
      case PredefinedTimeRange.ThisHalfYear:
        return { start: startOfMonth, end: sixMonthsAhead };
      case PredefinedTimeRange.LastHalfYear:
        const sixMonthsAgo = new Date(startOfMonth);
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
        return { start: sixMonthsAgo, end: startOfMonth };
      case PredefinedTimeRange.ThisWeek:
        return { start: startOfWeek, end: endOfWeek };
      case PredefinedTimeRange.LastWeek:
        const lastWeekStart = new Date(startOfWeek);
        lastWeekStart.setDate(startOfWeek.getDate() - 7);
        const lastWeekEnd = new Date(endOfWeek);
        lastWeekEnd.setDate(endOfWeek.getDate() - 7);
        return { start: lastWeekStart, end: lastWeekEnd };
      case PredefinedTimeRange.ThisYear:
        return { start: startOfYear, end: endOfYear };
      case PredefinedTimeRange.LastYear:
        return { start: new Date(now.getFullYear() - 1, 0, 1), end: new Date(now.getFullYear() - 1, 11, 31) };
      case PredefinedTimeRange.AllTime:
        return { start: new Date(0), end: new Date(9999, 11, 31) };
      default:
        throw new Error('Invalid range format');
    }
  } else if (typeof range === 'object' && range.type === 'Custom') {
    return { start: new Date(range.startDate), end: new Date(range.endDate) };
  } else {
    throw new Error('Invalid range format');
  }
};

export const getTimeRange = (range: TimeRange) => {
  return calculateTimeRange(range);
};

export function isWithinTimeRange(milestone: TimelineMilestoneData, timeRange: TimeRange) {
  const { start, end } = calculateTimeRange(timeRange);

  const milestoneStart = new Date(milestone.startDate);
  const milestoneEnd = new Date(milestone.endDate);

  return milestoneStart <= end && milestoneEnd >= start;
}

const placeMilestonesInLayers = (
  milestones: TimelineMilestoneData[],
  assignProperties: (milestone: TimelineMilestoneData, layerIndex: number) => TimelineLayerItem
): TimelineLayerItem[][] => {
  let layers: TimelineLayerItem[][] = [[]];

  // Sort milestones based on their priority
  milestones.sort((a, b) => (a.priority || Infinity) - (b.priority || Infinity));

  logger.debug('Layer count:', layers.length);

  milestones.forEach((milestone) => {
    let placed = false;

    logger.debug('Placing milestone: ', milestone.okr?.objective);
    logger.debug('Layer count:', layers.length);

    // Try to place milestone in any suitable layer
    for (let i = 0; i < layers.length; i++) {
      const layer = layers[i];

      logger.debug('Milestone date ranges in current layer:', layer.map(item => ({ start: item.milestone.startDate, end: item.milestone.endDate })));

      if (layer.every(item => item.milestone.endDate <= milestone.startDate || item.milestone.startDate >= milestone.endDate)) {
        layer.push(assignProperties(milestone, i));
        placed = true;

        logger.debug('Placed in layer', i);

        break;
      }
    }

    // If not placed, create a new layer
    if (!placed) {
      const newLayerIndex = layers.length;
      layers.push([assignProperties(milestone, newLayerIndex)]);

      logger.debug('Placed it in new layer', newLayerIndex);
    }
  });

  // Remove empty layers
  // layers = layers.filter(layer => layer.length > 0);

  return layers;
};


/**
 * Organizes a list of milestones into multiple layers, ensuring that milestones with overlapping 
 * time periods do not reside in the same layer. It first tries to place milestones in their priority
 * layer and if not possible, it iterates through the layers closest to the priority layer.
 * If no suitable layer is found, it creates a new layer for the milestone.
 * 
 * @param milestones - Array of milestones to be organized into layers.
 * @param startDate - The start date of the timeline.
 * @param endDate - The end date of the timeline.
 * @returns An array of layers, each containing the milestones that do not overlap in time.
 */
export const assignTimelinePropertiesToMilestones = (
  milestones: TimelineMilestoneData[],
  startDate: string,
  endDate: string
): TimelineLayerItem[][] => {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  const totalDuration = endDateObj.getTime() - startDateObj.getTime();

  // Make sure we filter out milestones that have a start date after end date
  milestones = milestones.filter(milestone => {
    const milestoneStartDateObj = new Date(milestone.startDate);
    const milestoneEndDateObj = new Date(milestone.endDate);

    return milestoneStartDateObj < milestoneEndDateObj && milestoneStartDateObj < endDateObj && milestoneEndDateObj >= startDateObj;
  });

  let result = placeMilestonesInLayers(milestones, (milestone, layerIndex) => {
    const milestoneStartDateObj = new Date(milestone.displayStartDate ?? milestone.startDate);
    const milestoneEndDateObj = new Date(milestone.displayEndDate ?? milestone.endDate);

    const startPercent = Math.round(((milestoneStartDateObj.getTime() - startDateObj.getTime()) / totalDuration) * 10000) / 100;
    const endPercent = Math.round(((milestoneEndDateObj.getTime() - startDateObj.getTime()) / totalDuration) * 10000) / 100;
    const widthPercent = endPercent - startPercent;

    return {
      milestone: { ...milestone, priority: layerIndex + 1 },
      startPercent,
      endPercent,
      widthPercent,
      layerIndex
    };
  });

  return result;
};

/**
 *  1. Call move(M1, SL, TL) where M1 is the id of milestone we are moving, SL is the source layer and TL is the target layer
 *  2. Remove M1 from SL
 *  3. Push M1 to DL
 *  4. For every conflicting item M2 with M1, call move(M2, TL, TL + 1)
 */
export const moveMilestoneOnTimeline = (
  stackedMilestones: TimelineLayerItem[][],
  sourceLayer: number,
  sourceMilestoneId: string,
  destinationLayer: number
): { newLayers: TimelineLayerItem[][], impactedMilestones: TimelineLayerItem[] } => {
  // Create a deep copy of the stackedMilestones to avoid mutation
  const layers = stackedMilestones.map(layer => layer.slice());
  const impactedMilestones: TimelineLayerItem[] = [];

  // Call the recursive move function
  moveMilestone(layers, sourceLayer, sourceMilestoneId, destinationLayer, impactedMilestones);

  return { newLayers: layers, impactedMilestones };
};

const moveMilestone = (
  layers: TimelineLayerItem[][],
  sourceLayer: number,
  sourceMilestoneId: string,
  destinationLayer: number,
  impactedMilestones: TimelineLayerItem[]
) => {
  if (sourceLayer === destinationLayer) {
    return;
  }

  // Find the source index based on the sourceMilestoneId
  const sourceIndex = layers[sourceLayer]?.findIndex(item => item.milestone.id === sourceMilestoneId);

  // Check if the source layer and source index are valid
  if (sourceIndex === -1 || sourceIndex === undefined) {
    logger.error('Invalid sourceLayer or sourceMilestoneId', { sourceLayer, sourceMilestoneId });
    return;
  }

  // Remove the milestone from the source layer
  const [movedLayerItem] = layers[sourceLayer].splice(sourceIndex, 1);

  // Ensure the target layer exists
  while (layers.length <= destinationLayer) {
    layers.push([]);
  }

  // Update priority and layer index
  movedLayerItem.milestone.priority = destinationLayer + 1;
  movedLayerItem.layerIndex = destinationLayer;

  // Add the milestone to the target layer
  layers[destinationLayer].push(movedLayerItem);

  // Add the moved item to impacted milestones
  impactedMilestones.push(movedLayerItem);

  // Find conflicting items and recursively move them
  const conflictingItems = layers[destinationLayer].filter(
    item => item.milestone.id !== movedLayerItem.milestone.id &&
      !(movedLayerItem.endPercent <= item.startPercent || movedLayerItem.startPercent >= item.endPercent)
  );

  for (const conflictingItem of conflictingItems) {
    // Recursively move the conflicting item to the next layer
    moveMilestone(layers, destinationLayer, conflictingItem.milestone.id, destinationLayer + 1, impactedMilestones);
  }
};

export const getOKRObjectiveText = (okr: OKRData, index: number) => {
  return okr && okr.objective ? okr.objective : `Objective ${index + 1}`;
};

export const getMileStoneObjectiveText = (okrs: OKRData[], milestoneId: string, index: number) => {
  const okr = okrs.find((okr) => okr.id === milestoneId);
  return getOKRObjectiveText(okr, index);
};

export const addUpdatedPlanIdToCache = async (userId: string, planId: string) => {
  try {
    const response = await fetch('/api/addUpdatedPlanId', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, planId }),
    });

    if (!response.ok) {
      throw new Error(`Error adding plan ID: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Success:', data);
  } catch (error) {
    console.error('Failed to add updated plan ID:', error);
  }
};

export function debounce(func, wait) {
  let timeout;
  return function (...args) {
    logger.debug(`Debounce triggered for function: ${func.name}`);

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      logger.debug(`Debounced Function executed: ${func.name}`);

      func.apply(this, args);
    }, wait);
  };
}


export const generateHashCode = (obj) => {
  let hash = 0;
  const str = JSON.stringify(obj);
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
